<style>
.result {
  width: 95%;
  margin-top: 12px;
  border: 1px solid rgb(159, 196, 172);
  border-radius: 12px;
  padding: 4px;
}
.app-header {
  width: 100%;
  background-color: #d8de63;
  background-image: linear-gradient(
    to right,
    rgb(255, 185, 55),
    rgb(250, 250, 151)
  );

  color: #fff;
  padding: 2px 12px;
  font-weight: bold;
}
.stext {
  border: solid 1px #ddd;
  padding: 4px;
  margin: 4px 10px;
  width: 90%;
  border-radius: 8px;
  font-size: 1.2em !important;
  background-color: #f0f0f0;
  color: #000;
  line-height: 2em;
}

label {
  font-size: 1.2em !important;
  padding: 4px;
}
.lines {
  width: 60px;
  font-size: 1.2em !important;
  padding: 0px 10px;
}
.sel-algo {
  width: 140px;
  font-size: 1.2em !important;
  padding: 4px;
}

textarea {
  min-height: 480px;
  width: 98%;
}
.prepro {
  width: 95%;
  margin-top: 12px;
  padding: 4px 8px;
  border: solid 1px #ccc;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
  padding: 12px;
}
.month-node {
  background-color: rgb(167, 217, 147);
  float: left;
  border: 2px solid rgb(159, 196, 172);
  padding: 5px 10px;
  margin: 10px 20px;
  color: #111;
  font-size: 1.2em;
  border-radius: 12%;
}

.month-node:hover {
  background-color: rgb(191, 241, 171);
  font-weight: bold;
}
.cons {
  margin: 18px;
  padding: 14px;
}
</style>

<template>
  <div class="py-0 container-fluid">
    <div class="app-header">
      <span>Summarization console</span>
    </div>
    <div class="row">
      <div class="cons col-lg-12col-md-12 mb-md-0 mb-4">
        <label for="Lines">要約後の行数</label>
        <input class="lines" id="lines" v-model="lines" />
        <label for="algo">アルゴリズム</label>
        <select class="sel-algo" name="algo" id="algo-sel" v-model="algo">
          <option v-for="aa in algos" :value="algo" :key="aa">
            {{ aa }}
          </option>
        </select>
        <textarea
          v-model="text"
          placeholder="please write document here"
          @change="changeText"
        ></textarea>
        <a
          v-if="isReady"
          class="btn bg-gradient-success mt-1"
          href="#"
          type="button"
          @click="summarize()"
          >要約する</a
        >
        <p v-else>要約中......</p>

        <div class="result">
          <p>結果</p>
          <span style="color: red">{{ error }}</span>
          <p v-for="(t, index) in result" :key="index">
            <span class="stext">{{ t }}</span>
          </p>
        </div>
        <div class="prepro">
          (送信データ確認用)
          <pre
            >{{ prepro }}
    </pre
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  //components: { NewsCardV2 },
  data() {
    return {
      error: "",
      render: 0,
      lines: 7,
      algo: "lex",
      text: "",
      prepro: "",
      algos: ["lex", "txt", "red", "luh", "sum", "kls", "lsa", "edm"],
    };
  },
  computed: {
    result() {
      return this.$store.state.samy.result;
    },
    isReady() {
      return this.$store.state.apiReady;
    },
  },
  updated() {},
  mounted() {
    this.$store.commit("onAPI", true);
  },
  methods: {
    summarize() {
      var URL = "http://localhost:8000/samy/";
      if (process.NODE_ENV != "development")
        URL = "https://samyapi.vpress.asia/samy/";
      this.error = "";
      this.$store.commit("onClearResult");
      var samyAPI_TOKEN = "fuelzz";
      var lines = this.lines;
      var data = {
        token: samyAPI_TOKEN,
        text: this.prepro,
        algo: this.algo,
        lines: lines,
      };
      this.$store.commit("onAPI", false);
      console.log(data);
      this.axios
        .post(URL, data)
        .then((res) => {
          console.log(res.data);
          this.$store.commit("onSamy", res.data);
          this.$store.commit("onAPI", true);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("onAPI", true);
          this.error =
            err.response.data.detail + "\n" + "APIエラーで実行できません";
        });
    },
    changeText() {
      var desc = this.text.replace(/^\s*[\r\n]/gm, "");
      desc = desc.replace(/ /g, "");
      desc = desc.replace(/\n/g, "");
      desc = desc.replace(/『/g, "");
      desc = desc.replace(/』/g, "");
      desc = desc.replace(/「/g, "");
      desc = desc.replace(/」/g, "");
      desc = desc.replace(/"/g, "");
      //this.description = desc;;
      this.prepro = desc;
    },
    endYear(date) {
      if (date.split("-")[1] == "12") return true;
      return false;
    },
  },
};
</script>
