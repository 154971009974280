import { createStore } from "vuex";

export default createStore({
  state: {
    // app auth
    user: null, //user object
    mail: "",
    token: "",
    isAuth: false,
    //samy app
    apiReady: true,
    samy: { result: [] },
  },
  getters: {
    getUser: (state) => () => {
      return state.mail;
    },
    getArticle: (state, idx) => () => {
      if (state.newsAry.length < idx) return state.newsAry[idx];
      console.log("Article index overflow");
      return null;
    },
  },
  actions: {
    checkLogin({ commit }) {
      console.log("checkLogin");
      commit("checkSessionToken");
    },
    auth({ commit }, user) {
      console.log(user);
      commit("onAuthChanged", user.mail, user.token);
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
  },
  mutations: {
    onAuthChanged(state, mail, token) {
      state.mail = mail;
      state.token = token;
      state.isAuth = true;
    },
    onSamy(state, samy) {
      state.samy = samy;
    },
    onClearResult(state) {
      state.samy = { result: [] };
    },
    onAPI(state, flg) {
      state.apiReady = flg;
    },
    // user
    onUA(state, ua) {
      state.ua = ua;
    },
    onUserReset(state) {
      state.users = [];
    },
    onLoginUser(state, obj) {
      state.loginUser = obj;
    },
  },
});
