//import firebase from "firebase/compat/app";
import { onBeforeUnmount } from "vue";

import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import { initializeApp } from "firebase/app";
/*
import {
  doc,
  getFirestore,
  collection,
  addDoc,
  getDocs,
  orderBy,
  where,
  query,
  updateDoc,
} from "firebase/firestore";
*/
import "firebase/compat/auth";

import store from "./store";
import appRouter from "./router";

// database v2
//import { User, Month, Stamp } from "./newsData";

console.log(
  " firebase initialization start----------------------------------------"
);
const firebaseConfig = {
  apiKey: "AIzaSyDJZwDc5uq_RI0dAzjjq8od2jSEm2Gktho",
  authDomain: "timestamp-v2.firebaseapp.com",
  projectId: "timestamp-v2",
  storageBucket: "timestamp-v2.appspot.com",
  messagingSenderId: "557520168004",
  appId: "1:557520168004:web:35ca701d5c77e0201b0811",
};

// setup firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
/*
const db = getFirestore(firebaseApp);
const coTS = "timestamp";
const coMon = "months";
const coUser = "tsusers";
const tsDB = collection(db, coTS);
const monDB = collection(db, coMon);
const userDB = collection(db, coUser);
console.log("!!! add auth listener --------------------");
*/
export const authListener = onAuthStateChanged(auth, function (user) {
  console.log(user);
});
onBeforeUnmount(() => {
  // clear up listener
  authListener();
});

// util ----------------------------

export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

// AUTH ---------------------------------------------------------------------------------------------------
export function authInit() {
  setPersistence(auth, browserSessionPersistence);
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// -------------------------------------------
export function onAuthByEmail(mail, passwd) {
  if (onAuthTest()) {
    onAuthLogout();
  }
  console.log("onAuthChanged");
  signInWithEmailAndPassword(auth, mail, passwd)
    .then(
      // 成功時の処理
      (data) => {
        console.log("login succeeded.");
        store.commit("onAuthChanged", data.user.email, data.user.accessToken);
        appRouter.replace("/cons");
      }
    )
    .catch(
      // エラー時の処理
      (error) => {
        console.log(error);
        alert("メールアドレスとpasswordの組み合わせが違うようです。");
        store.commit("onLogout");
      }
    );
}

//--------------------------
export function onAuthLogout() {
  auth.signOut().then(() => {
    store.commit("onLogout");
    appRouter.replace("login");
  });
}
//--------------------------
export function onAuthTest() {
  var user = auth.currentUser;
  console.log(user);
  if (!user) return false;
  return user.accessToken != "";
}
