import { createRouter, createWebHistory } from "vue-router";
import SamyCons from "@/views/sammary.vue";
import SignIn from "@/views/SignIn.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/cons",
    name: "samyCons",
    component: SamyCons,
  },
];

const appRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

import store from "../store";

appRouter.beforeEach((to, from, next) => {
  // console.log("router before each---->" + to.path);
  var token = store.state.token;
  // var token = "";
  //console.log(token);

  if (token == "") {
    console.log("no login session");
    if (to.path != "/sign-in") {
      appRouter.push("/sign-in");
    }

    //this.$store.dispatch("checkLogin");
  }
  next();
});

export default appRouter;
